import React, { FC } from "react";
import Header from "../../components/Header";
import { Box, useTheme } from "@mui/material";
import ArticleDistributionTable from "../../components/gurupress/ArticleDistributionTable";

const ArticleDistributionPage: FC = () => {
  const theme = useTheme();
  return (
    <Box mx={20} my={5}>
      <Header title="Auswertung externe Artikel" subtitle="" />
      <ArticleDistributionTable />
    </Box>
  );
};

export default ArticleDistributionPage;
