import { getFormattedTick } from "../../helpers/chartUtils";
import { ResponsiveLine, Serie } from "@nivo/line";
import React from "react";
import { useChartTheme } from "../../themes/chartTheme";

interface ResponsiveLineChartProps {
  data: Serie[];
  display: string;
  customTooltip: any;
}

const ResponsiveLineChart: React.FC<ResponsiveLineChartProps> = ({
  data,
  display,
  customTooltip,
}) => {
  const chartTheme = useChartTheme();
  return (
    <ResponsiveLine
      data={data}
      theme={chartTheme}
      tooltip={customTooltip}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        stacked: false,
        min: "auto",
        max: "auto",
      }}
      axisTop={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Datum",
        legendOffset: 36,
        legendPosition: "middle",
        format: (value) => getFormattedTick(display, value),
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
      }}
      lineWidth={2} // Default line width for subscribers and unsubscribes
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
        "legends",
      ]}
      // Customize the 'lines' layer to adjust the net subscribers line
      pointSize={6}
      colors={{ datum: "color" }}
      pointColor={{ theme: "background" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default ResponsiveLineChart;
