import React, { memo, useContext, useEffect, useState } from "react";
import { Serie } from "@nivo/line";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";
import { formatNumber } from "../../helpers/numberUtils";
import ResponsiveLineChart from "../widgets/ResponsiveLineChart";

interface IAPIResponse {
  date: string;
  sois: number;
  dois: number;
  doi_rate: number;
}

interface IAggregatedData extends IAPIResponse {}

interface CustomPoint {
  serieId: string | number;
  data: {
    yFormatted: number | string;
    xFormatted?: number | string;
  };
}

const getData: (filterState: IFilterState) => Promise<IAPIResponse[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/traffic-doi-rate",
    options
  )) as IAPIResponse[];
};

const aggregateData = (
  data: IAPIResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly"
): IAggregatedData[] => {
  let groupedData: Record<string, IAPIResponse[]> = {};
  switch (interval) {
    case "daily":
      return data;
    case "weekly":
      groupedData = groupBy(data, (item) => getISOWeek(item.date));
      break;
    case "monthly":
      groupedData = groupBy(data, (item) => getMonth(item.date));
      break;
    default:
      throw new Error("Invalid interval provided");
  }
  return Object.entries(groupedData).map(([date, items]) => {
    const sois = items.reduce((sum, curr) => sum + curr.sois, 0);
    const dois = items.reduce((sum, curr) => sum + curr.dois, 0);
    return {
      date,
      sois: sois,
      dois: dois,
      doi_rate: sois > 0 ? (dois / sois) * 100 : 0,
    };
  });
};

const CustomTooltip = memo(({ point }: { point: CustomPoint }) => {
  return (
    <div
      style={{
        padding: "12px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      {`${point.serieId}: ${formatNumber(
        point.data.yFormatted as number,
        2
      )} %`}
    </div>
  );
});

const TrafficDoiRateChart: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<Serie[]>([]);

  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily"
        );
        const lineData: Serie[] = [
          {
            id: "DOI-Rate",
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.doi_rate * 100, // display as percentage
            })),
            color: "#222",
          },
          // further data series ( lines ) can be added here
        ];
        setData(lineData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  return (
    <ResponsiveLineChart
      data={data}
      customTooltip={CustomTooltip}
      display={filterState.display}
    ></ResponsiveLineChart>
  );
};

export default TrafficDoiRateChart;
