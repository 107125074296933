import axios from "axios";

interface IFetchDataOptions {
  [key: string]:
    | string
    | string[]
    | Date
    | number
    | undefined
    | number[]
    | boolean;
}

export interface IResponseData {
  [key: string]: any; // The response can contain any key-value pairs
}

async function fetchData<T extends IResponseData>(
  endpoint: string,
  options: IFetchDataOptions,
  expectArray = true
): Promise<T[] | T> {
  let url = (process.env.REACT_APP_API_BASE_URL || "") + endpoint;
  const parameters = [];

  for (const key in options) {
    const value = options[key];

    if (Array.isArray(value)) {
      value.forEach((item) =>
        item !== "all" ? parameters.push(`${key}[]=${item}`) : ""
      );
    } else if (value !== undefined && value !== "all") {
      parameters.push(`${key}=${value}`);
    }
  }

  if (parameters.length > 0) {
    url += "?" + parameters.join("&");
  }
  console.log("Fetch URL", url);
  const response = await axios.get<T[]>(url);
  if (expectArray) {
    return response.data;
  } else {
    if (Array.isArray(response.data)) {
      throw new Error(
        `Expected object but received array from endpoint: ${endpoint}`
      );
    }
    return response.data as T;
  }
}

export default fetchData;
