// ./react-app/src/components/LeadsChart.tsx
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import fetchData from "../helpers/fetchData";
import { useChartTheme } from "../themes/chartTheme";
import { formatDateToDM, parseDate } from "../helpers/chartUtils";
import { FilterContext, IFilterState } from "../contexts/FilterContext";
import { dateToSqlDate } from "../helpers/dateFuncs";
import { leadStatusOptions } from "./FilterBar";

interface IData {
  [key: string]: number | string;
}

interface IGroupedResponse {
  date: string;

  [key: string]: number | string;
}

const getAndProcessData = async (filterState: IFilterState) => {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
    lead_status: filterState.leadStatus,
    source: filterState.leadSource,
    group_by: [filterState.lpsLeadsGroupBy, "date_column_as_date"],
  };

  return await fetchData("/api/leads-per-status", options);
};

const LpsLeadsChart: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<IData[]>([]);

  useEffect(() => {
    getAndProcessData(filterState)
      .then((rawData) => {
        console.dir(rawData);
        const transformedData = rawData.reduce(
          (acc: IGroupedResponse[], curr: IGroupedResponse) => {
            const existingEntry = acc.find(
              (entry: IGroupedResponse) => entry.date === curr.date
            );
            const key = curr[filterState.lpsLeadsGroupBy];
            //try to map with leadStatus options for user display, if not found use key
            const newKey = leadStatusOptions[key]
              ? leadStatusOptions[key]
              : key;
            if (existingEntry) {
              existingEntry[newKey] = curr.leads;
            } else {
              acc.push({
                date: curr.date,
                [newKey]: curr.leads,
              });
            }
            return acc;
          },
          []
        );
        setData(transformedData);
      })
      .catch((error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  const chartTheme = useChartTheme();
  return (
    <ResponsiveBar
      data={data}
      theme={chartTheme}
      keys={Object.keys(data[0] || {}).filter((key) => key !== "date")}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Datum",
        legendPosition: "middle",
        legendOffset: 32,
        format: (d) => {
          const date = parseDate(d);
          return date ? formatDateToDM(date) : "";
        },
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Leads",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableGridX={false}
      enableGridY={false}
      enableLabel={true}
      label={(bar) => `${bar.value}`}
      labelFormat={(value) => `${value}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      // to display label on top of the bar and in bold letters
      layers={[
        "grid",
        "axes",
        "bars",
        "legends",
        ({ bars }) => {
          // calculate sums for each index value
          const sums: { [index: string]: number } = {};
          let lastId = "";
          bars.forEach((bar) => {
            if (sums[bar.data.indexValue as string] === undefined)
              sums[bar.data.indexValue as string] = 0;
            sums[bar.data.indexValue as string] += bar.data.value as number;
            lastId = bar.data.id as string;
          });
          return (
            <g>
              {bars.map((bar) => {
                // If the indexValue is the same as the previous one, return null (do not render text)
                if ((bar.data.id as string) !== lastId) {
                  return null;
                }
                return (
                  <text
                    key={bar.key}
                    x={bar.x + bar.width / 2}
                    y={bar.y - 15} // Position it slightly above the bar
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fill: colors.grey[100], // Use black color
                      fontWeight: "bold", // Make it bold
                    }}
                  >
                    {sums[bar.data.indexValue as string]}
                  </text>
                );
              })}
            </g>
          );
        },
      ]}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 500,
          translateY: 200,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LpsLeadsChart;
