// ./react-app/src/components/ListGrowthChart.tsx
import React, { memo, useContext, useEffect, useState } from "react";
import { Serie } from "@nivo/line";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";
import ResponsiveLineChart from "../widgets/ResponsiveLineChart";

interface IListGrowthDailyResponse {
  date: string;
  subscribed: number;
  unsubscribed: number;
}

const getData: (
  filterState: IFilterState
) => Promise<IListGrowthDailyResponse[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/mailchimp/list-growth",
    options
  )) as IListGrowthDailyResponse[];
};

const aggregateData = (
  data: IListGrowthDailyResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly"
): Record<string, any>[] => {
  switch (interval) {
    case "daily":
      return data.map((item) => ({
        date: item.date,
        subscribed: item.subscribed,
        unsubscribed: item.unsubscribed,
      }));
    case "weekly":
    case "monthly":
      const groupedData = groupBy(data, (item) => {
        // For weekly or monthly grouping, you would need to convert the date
        // to a week number or a month string depending on the interval
        // For simplicity, let's assume we have a function that does that:
        return interval === "weekly"
          ? getISOWeek(item.date)
          : getMonth(item.date);
      });
      return Object.entries(groupedData).map(([date, items]) => ({
        date,
        subscribed: items.reduce((sum, curr) => sum + curr.subscribed, 0),
        unsubscribed: items.reduce((sum, curr) => sum + curr.unsubscribed, 0),
      }));
    default:
      return [];
  }
};

interface CustomPoint {
  serieId: string | number;
  data: {
    yFormatted: number | string;
    xFormatted?: number | string;
  };
}

const CustomTooltip = memo(({ point }: { point: CustomPoint }) => {
  return (
    <div
      style={{
        padding: "12px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      {`${point.serieId}: ${point.data.yFormatted}`}
    </div>
  );
});

const ListGrowthLineChart: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<Serie[]>([]);

  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily"
        );
        const lineData: Serie[] = [
          {
            id: "Neue Anmelder",
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.subscribed,
            })),
            color: "green",
          },
          {
            id: "Abmelder",
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.unsubscribed,
            })),
            color: "red",
          },
        ];
        setData(lineData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  return (
    <ResponsiveLineChart
      data={data}
      display={filterState.display}
      customTooltip={CustomTooltip}
    ></ResponsiveLineChart>
  );
};

export default ListGrowthLineChart;
